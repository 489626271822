
import { defineComponent, ref } from 'vue';
import {
  Bioprobes,
  Characteristics,
  Cohort,
  Contact,
  Recruitment,
  CoreVariable,
  SampleTypes,
} from '@/types/Cohort';
import useCohorts from '@/composables/useCohorts';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import Divider from 'primevue/divider';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { TableEvent } from '@/types/TableEvent';
import Chips from 'primevue/chips';
import Editor from 'primevue/editor';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'Generator',
  components: {
    InputText,
    Dropdown,
    Checkbox,
    Calendar,
    MultiSelect,
    Divider,
    Accordion,
    AccordionTab,
    Button,
    Toolbar,
    DataTable,
    Column,
    Chips,
    Editor,
  },
  directives: {
    tooltip: Tooltip,
  },
  setup() {
    const {
      cohorts,
      getRoute,
      COHORT_TYPES,
      COHORT_TYPES_2,
      STUDY_TYPES,
      INDICATION_FIELDS,
      PARTICIPANT_TYPES,
      GENDER_TYPES,
      PRIMARY_BLOOD_TUBE_TYPES,
      URINE_COMPONENT_TYPES,
      SMEAR_TYPES,
    } = useCohorts();

    let formCohort = ref({} as Cohort);
    let isCohortShown = ref(false);

    const newSelectedCohort = () => {
      formCohort.value = {
        acronym: '',
        name: '',
        description: '',
        projectUrl: '',
        studyObjectives: '',
        contacts: [] as Contact[],
        characteristics: {} as Characteristics,
        recruitment: {} as Recruitment,
        coreVariables: [],
        bioprobes: {
          available: false,
          sampleLocatorUrl: '',
          sampleTypes: {} as SampleTypes,
        } as Bioprobes,
      } as Cohort;
      showCohort();
    };

    const showCohort = () => {
      isCohortShown.value = true;
    };

    const downloadCohortAsJson = () => {
      if (!formCohort.value.name || formCohort.value.name === '') return;
      const blob = new Blob([JSON.stringify(formCohort.value as Cohort)], {
        type: 'application/json',
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = getRoute(formCohort.value).substr(0, 31) + '.json';
      link.click();
      URL.revokeObjectURL(link.href);
    };

    const resetSelectedCohort = () => {
      formCohort.value = {} as Cohort;
      isCohortShown.value = false;
    };

    const originalCoreVariableRows = ref([] as CoreVariable[]);
    const editingCoreVariableRows = ref([] as CoreVariable[]);
    const onCoreVariableRowEditInit = (event: TableEvent) => {
      originalCoreVariableRows.value[event.index] = {
        ...formCohort.value.coreVariables[event.index],
      };
    };
    const onCoreVariableRowEditCancel = (event: TableEvent) => {
      formCohort.value.coreVariables[event.index] =
        originalCoreVariableRows.value[event.index];
    };
    const onCoreVariableRowDelete = (event: TableEvent) => {
      if (event.index >= 0) {
        formCohort.value.coreVariables.splice(event.index, 1);
      }
    };
    const addEmptyCoreVariable = () => {
      formCohort.value.coreVariables.push({} as CoreVariable);
    };

    const originalContactRows = ref([] as Contact[]);
    const editingContactRows = ref([] as Contact[]);
    const onContactRowEditInit = (event: TableEvent) => {
      originalContactRows.value[event.index] = {
        ...formCohort.value.contacts[event.index],
      };
    };
    const onContactRowEditCancel = (event: TableEvent) => {
      formCohort.value.contacts[event.index] =
        originalContactRows.value[event.index];
    };
    const onContactRowDelete = (event: TableEvent) => {
      if (event.index >= 0) {
        formCohort.value.contacts.splice(event.index, 1);
      }
    };
    const addEmptyContact = () => {
      formCohort.value.contacts.push({} as Contact);
    };
    return {
      downloadCohortAsJson,
      resetSelectedCohort,
      newSelectedCohort,
      showCohort,
      cohorts,
      formCohort,
      isCohortShown,
      COHORT_TYPES,
      COHORT_TYPES_2,
      STUDY_TYPES,
      INDICATION_FIELDS,
      PARTICIPANT_TYPES,
      GENDER_TYPES,
      PRIMARY_BLOOD_TUBE_TYPES,
      URINE_COMPONENT_TYPES,
      SMEAR_TYPES,
      onContactRowEditInit,
      onContactRowEditCancel,
      onContactRowDelete,
      addEmptyContact,
      editingContactRows,
      onCoreVariableRowEditInit,
      onCoreVariableRowEditCancel,
      onCoreVariableRowDelete,
      addEmptyCoreVariable,
      editingCoreVariableRows,
    };
  },
});
